import * as React from 'react'
import { useCallback } from 'react'
import { getImage, GatsbyImage } from 'gatsby-plugin-image'
import { graphql, useStaticQuery } from 'gatsby'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Map from '../components/homepage/Map'
import InputField from '../components/form/InputField'
import StillHaveQuestions from '../components/StillHaveQuestions'
import FormWrapper from '../components/form/FormWrapper'
import useFormState from '../components/form/hooks/useFormState'
import PropTypes from 'prop-types'
import isEmail from '../components/form/validators/isEmail'
import isPhone from '../components/form/validators/isPhone'
import { processGatsbyImageDataMock } from '../common/gatsbyImageData'

const CheckboxOptions = ({ option, form, name, onChange }) => {
  const onChangeHandler = e => {
    if (onChange) {
      onChange(e, name)
    }
  }
  return (
    <label>
      <input
        type="checkbox"
        className="peer m-2 h-4 w-4 translate-y-[.25rem] border border-black accent-red-700 sm:h-5 sm:w-5"
        value={option}
        onChange={onChangeHandler}
        checked={form[name]}
      />
      <p className=" inline-block text-gray-800 hover:text-black peer-checked:text-black ">
        {option}
        {form[name]}
      </p>
    </label>
  )
}
CheckboxOptions.propTypes = {
  option: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
}

const CatalogRequest = () => {
  const [form, setForm, updateForm] = useFormState({
    fName: '',
    lName: '',
    phone: '',
    email: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    newsletter: '',
    pcgsCoinNewsletter: false,
    instagram: false,
    subscribeEmail: false,
    facebook: false,
    google: false,
    hotelInRoomMagazine: false,
    elVenezolano: false,
    miamiBeachMagazine: false,
    miamiHerald: false,
    referredByFriend: false,
    other: false,
    expandRequest: true,
  })

  const handleChange = useCallback(
    (e, name) => {
      setForm(f => {
        let newForm = { ...f }
        newForm[name] = e.target.checked
        return newForm
      })
    },
    [setForm]
  )

  const { catalog } = useStaticQuery(graphql`
    query catalogCoverRequest {
      catalog: strapiCatalog {
        cover {
          gatsbyImageDataMock
        }
      }
    }
  `)
  processGatsbyImageDataMock(catalog.cover)

  return (
    <Layout className={'mx-auto'} breadcrumbs={false}>
      <Seo
        description="Sign up to receive a printed copy of our FREE monthly catalog. Get special discounts and more in your mailbox."
        title="Gray &amp; Sons Catalog Request"
      />
      <section className="mx-auto flex w-10/12 max-w-[1366px] flex-col flex-wrap py-10 md:flex-row">
        <h1 className="w-full text-center text-3xl font-bold text-red-700">
          SIGN UP TO RECEIVE A PRINTED COPY OF OUR FREE MONTHLY CATALOG
        </h1>
        <p className="mx-auto my-5 w-full max-w-[800px]">
          Our monthly print catalog is mailed to US clients only. International client will receive
          the digital edition of our catalog by email. Get $200 OFF your first purchase of $750 or
          more when you sign up for our free printed catalogs. Offer may not be combined with other
          discounts.
        </p>
        <div className="mx-auto flex w-full items-start justify-center lg:w-1/2">
          <GatsbyImage
            alt={'Rolex, Patek Philippe, GIA Diamond Ring and Studs'}
            image={getImage(catalog.cover)}
          />
          {/*<StaticImage*/}
          {/*  src={'../images/top-watch-buyer-img.png'}*/}
          {/*  alt={'Rolex, Patek Philippe, GIA Diamond Ring and Studs'}*/}
          {/*  className={''}*/}
          {/*/>*/}
        </div>

        <div className=" mx-auto flex w-full flex-col items-start justify-start lg:w-1/2">
          <FormWrapper
            form={form}
            updateForm={updateForm}
            name={'Catalog Request Form'}
            action={'/thank-you-catalog/'}
          >
            <div className={'grid grid-cols-4 gap-x-3'}>
              <p className="col-span-4 xl:text-lg">
                Fill out this form to receive $200 off your first purchase and our free 76 page
                monthly catalog To receive our Digital Catalog enter your e-mail below: <br />
                To receive our Physical Catalog enter your address:
                <span className="text-sm">
                  <br />
                  *US Customers Only. International Customers receive digital version.
                </span>
              </p>
              <InputField
                name={'fName'}
                label={'First Name'}
                form={form}
                onChange={updateForm}
                className={'col-span-4 lg:col-span-2'}
                required={true}
              />
              <InputField
                name={'lName'}
                label={'Last Name'}
                form={form}
                onChange={updateForm}
                className={'col-span-4 lg:col-span-2'}
                required={true}
              />
              <InputField
                name={'phone'}
                label={'Telephone'}
                form={form}
                validator={isPhone}
                onChange={updateForm}
                className={'col-span-4 lg:col-span-2'}
              />
              <InputField
                name={'email'}
                label={'E-Mail'}
                form={form}
                onChange={updateForm}
                validator={isEmail}
                className={'col-span-4 lg:col-span-2'}
                required={form['expandRequest'] ? false : true}
              />
              <div className="col-span-4 my-2">
                <CheckboxOptions
                  form={form}
                  onChange={handleChange}
                  name={'expandRequest'}
                  option={'Request Printed Catalog'}
                />
              </div>
              {/* Printed Catalog Request */}
              <div
                className={
                  'col-span-4 grid w-full origin-top grid-cols-4 gap-x-3  duration-200 ' +
                  (form['expandRequest'] ? ' h-[12rem] mb-5 ' : 'h-0 overflow-hidden mb-0')
                }
              >
                <InputField
                  name={'address1'}
                  label={'Address'}
                  form={form}
                  onChange={updateForm}
                  className={'col-span-3 lg:col-span-3'}
                  required={form['expandRequest'] ? true : false}
                />
                <InputField
                  name={'address2'}
                  label={'Suite'}
                  form={form}
                  onChange={updateForm}
                  className={'col-span-1 lg:col-span-1'}
                />
                <InputField
                  name={'city'}
                  label={'City'}
                  form={form}
                  onChange={updateForm}
                  className={'col-span-4'}
                  required={form['expandRequest'] ? true : false}
                />
                <InputField
                  name={'state'}
                  label={'State'}
                  form={form}
                  onChange={updateForm}
                  className={'col-span-3 lg:col-span-3'}
                  required={form['expandRequest'] ? true : false}
                />
                <InputField
                  name={'zip'}
                  label={'Zip'}
                  form={form}
                  onChange={updateForm}
                  className={'col-span-1 lg:col-span-1'}
                  required={form['expandRequest'] ? true : false}
                />
              </div>
              <div className="col-span-4 grid w-full grid-cols-1 sm:grid-cols-2 gap-y-4 sm:gap-y-2 z-10">
                <h2 className="col-span-1 sm:col-span-2 inline-block text-lg uppercase">
                  How did you hear about us?
                </h2>
                <CheckboxOptions
                  form={form}
                  onChange={handleChange}
                  name={'pcgsCoinNewsletter'}
                  option={'PCGS Coin Newsletter'}
                />
                <CheckboxOptions
                  form={form}
                  onChange={handleChange}
                  name={'instagram'}
                  option={'Instagram'}
                />
                <CheckboxOptions
                  form={form}
                  onChange={handleChange}
                  name={'subscribeEmail'}
                  option={'Email'}
                />
                <CheckboxOptions
                  form={form}
                  onChange={handleChange}
                  name={'facebook'}
                  option={'Facebook'}
                />
                <CheckboxOptions
                  form={form}
                  onChange={handleChange}
                  name={'google'}
                  option={'Google'}
                />
                <CheckboxOptions
                  form={form}
                  onChange={handleChange}
                  name={'hotelInRoomMagazine'}
                  option={'Hotel In-Room Magazine'}
                />
                <CheckboxOptions
                  form={form}
                  onChange={handleChange}
                  name={'elVenezolano'}
                  option={'El Venezolano'}
                />
                <CheckboxOptions
                  form={form}
                  onChange={handleChange}
                  name={'miamiBeachMagazine'}
                  option={'Miami Beach Magazine'}
                />
                <CheckboxOptions
                  form={form}
                  onChange={handleChange}
                  name={'miamiHerald'}
                  option={'Miami Herald'}
                />
                <CheckboxOptions
                  form={form}
                  onChange={handleChange}
                  name={'referredByFriend'}
                  option={'Referred By Friend'}
                />
                <CheckboxOptions
                  form={form}
                  onChange={handleChange}
                  name={'other'}
                  option={'Other'}
                />
              </div>
              <button className="col-span-4 mx-auto my-7 max-w-max bg-red-700 py-3 px-5 font-bold text-white hover:bg-red-800 md:mx-0">
                SEND REQUEST
              </button>
            </div>
          </FormWrapper>
        </div>
      </section>
      <hr className="w-10/12 max-w-[1000px] mx-auto" />
      <StillHaveQuestions />
      <Map />
    </Layout>
  )
}

export default CatalogRequest
